import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const CarIns = () => {
  const [listPackages, setlistPackages] = useState([]);

  const getData = () => {
    Axios.get("https://miadashapi.skwerzone.com/getDataCar").then(
      (response) => {
        setlistPackages(response.data);
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID", maxWidth: 30 },
    {
      field: "fullname",
      headerName: "Full Name",
      headerAlign: "left",
      align: "left",
      minWidth: 120,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      headerAlign: "left",
      align: "left",
      maxWidth: 80,
      cellClassName: "name-column",
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      headerAlign: "left",
      align: "left",
      maxWidth: 80,
      cellClassName: "name-column",
    },
    {
      field: "nationality",
      headerName: "Nationality",
      headerAlign: "left",
      align: "left",
      maxWidth: 80,
      cellClassName: "name-column",
    },
    {
      field: "ins_like",
      headerName: "Chosen Insurance Comp.",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "reg_location",
      headerName: "Registration Loc.",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "y_make",
      headerName: "Year make",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "reg_f_date",
      headerName: "First date of regis.",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "c_brand",
      headerName: "Car Brand",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "c_model",
      headerName: "Car Model",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "reg_type",
      headerName: "Reg. type",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "country_lic",
      headerName: "Country licensed",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "y_exp",
      headerName: "Year of driving exp.",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "cov_type",
      headerName: "Coverage Type",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "coverage",
      headerName: "Coverage",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "timeCreated",
      headerName: "Time Created",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
  ];

  return (
    <Box m="20px">
      <Header title="CUSTOMER DATA" subtitle="Car Insurance Inquiry" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.miaLightBlue[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.miaLightBlue[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {/* <DataGrid checkboxSelection rows={listPackages} columns={columns} /> */}
        <DataGrid
          checkboxSelection
          rows={listPackages}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default CarIns;
