import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const HomeIns = () => {
  const [listPackages, setlistPackages] = useState([]);

  const getData = () => {
    Axios.get("https://miadashapi.skwerzone.com/getDataHome").then(
      (response) => {
        setlistPackages(response.data);
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID", maxWidth: 30 },
    {
      field: "ownership",
      headerName: "Ownership",
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "home_type",
      headerName: "Home type",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "name-column",
    },
    {
      field: "building_value",
      headerName: "Building val.",
      headerAlign: "left",
      align: "left",
      maxWidth: 100,
      cellClassName: "name-column",
    },
    {
      field: "content_value",
      headerName: "Content val.",
      headerAlign: "left",
      align: "left",
      maxWidth: 80,
      cellClassName: "name-column",
    },
    {
      field: "content_value_price",
      headerName: "20k?",
      headerAlign: "left",
      align: "left",
      maxWidth: 40,
      cellClassName: "name-column",
    },
    {
      field: "p_p_value",
      headerName: "Personal property val.",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "p_p_value_price",
      headerName: "20k?",
      headerAlign: "left",
      align: "left",
      maxWidth: 40,
      cellClassName: "name-column",
    },
    {
      field: "ins_like",
      headerName: "Chosen Insurance Comp.",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "name-column",
    },
    {
      field: "coverage",
      headerName: "Coverage",
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      cellClassName: "name-column",
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },
    {
      field: "timeCreated",
      headerName: "Time Created",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column",
    },

    // {
    //   field: "remarks",
    //   headerName: "View More",
    //   flex: 1,
    //   renderCell: () => {
    //     return (
    //       <Box
    //         width="80%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={colors.greenAccent[600]}
    //         borderRadius="4px"
    //       >
    //         <ContentPasteSearchIcon />
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           VIEW MORE
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px">
      <Header title="CUSTOMER DATA" subtitle="Car Insurance Inquiry" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.miaLightBlue[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.miaLightBlue[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {/* <DataGrid checkboxSelection rows={listPackages} columns={columns} /> */}
        <DataGrid
          checkboxSelection
          rows={listPackages}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default HomeIns;
