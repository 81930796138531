import React, { useContext } from "react";
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
/*import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";*/
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
/*import SearchIcon from "@mui/icons-material/Search";*/
import LockIcon from "@mui/icons-material/Lock";
import LanguageIcon from "@mui/icons-material/Language";

const TopBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}
      {/* LOGOUT */}
      <a href="https://miadash.skwerzone.com/">
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
          alignItems="center"
          pl="10px"
        >
          <Typography variant="h6" fontWeight="600" color={colors.grey[100]}>
            Logout
          </Typography>
          <IconButton type="button" sx={{ p: 1 }}>
            <LockIcon />
          </IconButton>
        </Box>
      </a>
      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <a href="https://mia.skwerzone.com/">
          <IconButton>
            <LanguageIcon />
          </IconButton>
        </a>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TopBar;
