import React from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Header from "../../components/Header";
import LineGraph from "../../components/LineGraph";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Axios from "axios";
import { useState, useEffect } from "react";
import StatBox from "../../components/StatBox";

import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HomeIcon from "@mui/icons-material/Home";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState({
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    data5: [],
  });

  /* https://miadashapi.skwerzone.com/ */
  useEffect(() => {
    const fetchData = async () => {
      const [res1, res2, res3, res4, res5] = await Promise.all([
        Axios.get("https://miadashapi.skwerzone.com/getDataLineC"),
        Axios.get("https://miadashapi.skwerzone.com/getDataLineT"),
        Axios.get("https://miadashapi.skwerzone.com/getDataLineH"),
        Axios.get("https://miadashapi.skwerzone.com/getDataBarC"),
        Axios.get("https://miadashapi.skwerzone.com/getDataBarH"),
      ]);

      setData({
        data1: res1.data,
        data2: res2.data,
        data3: res3.data,
        data4: res4.data,
        data5: res5.data,
      });
    };

    fetchData();
  }, []);

  const sumC = data.data1.reduce(
    (total, currentValue) => (total = total + currentValue.y),
    0
  );
  const sumT = data.data2.reduce(
    (total, currentValue) => (total = total + currentValue.y),
    0
  );
  const sumH = data.data3.reduce(
    (total, currentValue) => (total = total + currentValue.y),
    0
  );

  const totalInter = sumC + sumT + sumH;

  console.log(data.data3);

  const company_list = [
    {
      name: "RSA",
      code: "RSA",
      url: "https://www.rsadirect.ae/",
      logo: "https://www.giggulf.ae/image/layout_set_logo?img_id=11683737&t=1674761515466",
    },
    {
      name: "GIG Gulf",
      code: "GIG",
      url: "https://www.giggulf.ae/en/",
      logo: "https://www.giggulf.ae/image/layout_set_logo?img_id=11683737&t=1674761515466",
    },
    {
      name: "Sukoon Insurance",
      code: "Sukoon",
      url: "https://www.sukoon.com/",
      logo: "https://www.sukoon.com/-/media/oic/oic-media/logo/sukoon-logo.png?h=100&iar=0&mh=100&w=225&hash=0278DE0E8E51C944C8CA9567696BC378",
    },
    {
      name: "Watania",
      code: "Watania",
      url: "https://www.watania.ae/",
      logo: "https://www.watania.ae/wp-content/uploads/logo-watania-1.png",
    },
    {
      name: "Salama",
      code: "Salama",
      url: "https://salama.ae/",
      logo: "https://salama.ae/wp-content/uploads/2020/10/Salama-Logo.svg",
    },
    {
      name: "Adnic",
      code: "Adnic",
      url: "https://adnic.ae/",
      logo: "https://adnic.ae/image/layout_set_logo?img_id=62129&t=1673339641058",
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        {/* DOWNLOAD BUTTON */}
        <Box>
          <a href="#" download="hello.txt">
            <Button
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                ":hover": {
                  bgcolor: colors.miaLightBlue[400],
                },
              }}
              download="hello.txt"
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </a>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 STAT BOXES */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={sumC}
            subtitle="Car Insurance"
            progress={sumC / totalInter}
            increase={`+${(sumC / totalInter) * 100}%`}
            icon={
              <DirectionsCarIcon
                sx={{ color: colors.miaLightBlue[400], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={sumT}
            subtitle="Travel Insurance"
            progress={sumT / totalInter}
            increase={`+${(sumT / totalInter) * 100}%`}
            icon={
              <ConnectingAirportsIcon
                sx={{ color: colors.miaLightBlue[400], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={sumH}
            subtitle="Home Insurance"
            progress={sumH / totalInter}
            increase={`+${(sumH / totalInter) * 100}%`}
            icon={
              <HomeIcon
                sx={{ color: colors.miaLightBlue[400], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 LINE GRAPH */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            display="grid"
            gridTemplateColumns="max-content 200px"
            ml="25px"
            mt="10px"
            gap="20px"
            alignItems="center"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color={colors.miaLightBlue[400]}
            >
              Customer Interaction
            </Typography>
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Total interactions: {sumC + sumT + sumH}
            </Typography>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineGraph
              isDashboard={true}
              lineData1={data.data1}
              lineData2={data.data2}
              lineData3={data.data3}
            />
          </Box>
        </Box>

        {/* ROW 2 INSURANCE COMP. LIST */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="grid"
            gridTemplateColumns="max-content 200px"
            ml="25px"
            mt="10px"
            gap="20px"
            alignItems="center"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color={colors.miaLightBlue[400]}
            >
              Insurance Companies
            </Typography>
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Total companies: 6
            </Typography>
          </Box>
          {company_list.map((item, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[900]}`}
              p="15px"
            >
              <Box width="70%">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <Button
                    sx={{
                      backgroundColor: colors.primary[400],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "600",
                      padding: "5px 10px",
                      justifyContent: "left",
                      ":hover": {
                        bgcolor: colors.miaLightBlue[400],
                      },
                    }}
                  >
                    {item.name}
                  </Button>
                </a>
              </Box>

              <Box width="15%">
                <Typography
                  color={colors.miaBlue[100]}
                  variant="h6"
                  fontWeight="600"
                >
                  {/*  */}
                  {data.data4.map((item2, index2) => {
                    return (
                      <div>
                        {item.name === item2.ins_comp ? (
                          <Box display="flex" alignItems="center">
                            <DirectionsCarIcon
                              sx={{
                                color: colors.miaLightBlue[400],
                                fontSize: "18px",
                              }}
                            />
                            :{item2.customer}
                          </Box>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </Typography>
              </Box>
              <Box width="15%">
                <Typography
                  color={colors.miaBlue[100]}
                  variant="h6"
                  fontWeight="600"
                >
                  {/*  */}
                  {data.data5.map((item3, index3) => {
                    return (
                      <div>
                        {item.name === item3.ins_comp ? (
                          <Box display="flex" alignItems="center">
                            <HomeIcon
                              sx={{
                                color: colors.miaLightBlue[400],
                                fontSize: "18px",
                              }}
                            />
                            :{item3.customer}
                          </Box>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
