import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/TopBar";
import Sidebar from "./scenes/global/Sidebar";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import SignIn from "./scenes/signin/SignIn";
import Dashboard from "./scenes/dashboard";
import TravelIns from "./scenes/travelIns";
import CarIns from "./scenes/carIns";
import HomeIns from "./scenes/homeIns";

import Team from "./scenes/team";


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const [showDash, setShowDash] = useState(false);

  const [adminName, setAdminName] = useState("Administrator");

  function view(){
    if (showDash === false){
      return <SignIn showDash = {showDash} setShowDash = {setShowDash} adminName = {adminName} setAdminName = {setAdminName} />;
    }else {
      return (
        <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app"> 
            <Sidebar isSidebar={isSidebar} adminName = {adminName} />
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/signIn" element={<SignIn />} />
                <Route path="/travelIns" element={<TravelIns />} />
                <Route path="/carIns" element={<CarIns />} />
                <Route path="/homeIns" element={<HomeIns />} />

                <Route path="/team" element={<Team />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
      );
    }
  }

  return (
    <>
      {view()}
    </>
  )
}

export default App;
